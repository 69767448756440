import React  from 'react'
import "./tabella.css"
import Checkbox from '@material-ui/core/Checkbox'
import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableRow from '@material-ui/core/TableRow'
import TableToolbar from './TableToolbar'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
const IndeterminateCheckbox = (clicks,select,row) => {
      let checked=false
      if(clicks.rows === undefined){
        return (
          <>
            <Checkbox  checked={checked} onChange={clicks} value={"null"} /> 
          </>
        )
      }
      if(clicks.select.indexOf(clicks.rows.index) !== -1 ){
        checked=true
      }
      return (
        <>
          <Checkbox  checked={checked} onChange={clicks.click} value={clicks.rows.id} /> 
        </>
      )
    }
  
  const inputStyle = {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
  }
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
  }) => {

    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(true)
  
    // We'll only update the external data when the input is blurred
    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
    if(value !== null){
    if(value.props !== undefined){
      return value
    }
  }
    return (
      <p style={inputStyle} >{value}</p>
    )
  }
  
  EditableCell.propTypes = {
    cell: PropTypes.shape({
      value: PropTypes.any.isRequired,
    }),
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
    }),
    column: PropTypes.shape({
      id: PropTypes.any.isRequired,
    })
  }
  
  const defaultColumn = {
    Cell: EditableCell,
  }
  const formatNumber=(num)=> {
    if(num.toString().length < 4 ){
      return num
    }

    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  
  const EnhancedTable = ({
    columns,
    data,
    skipPageReset,
    select2,
    click,
    switchs,
    totcasi,
    date,
    handledata,
    handlesort,
    handlesearch,
    globalFilter,
    premuto,
    HandleTot,
    totstate,
  }) => {
    const {
      getTableProps,
      headerGroups,
      prepareRow,
      page,
      gotoPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex,pageSize ,selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageSize: 5},
        disableSortBy:true,
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        select2,
        click,
    totcasi

      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        hooks.allColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox.  Pagination is a problem since this will select all
            // rows even though not all rows are on the current page.  The solution should
            // be server side pagination.  For one, the clients should not download all
            // rows in most cases.  The client should only download data for the current page.
            // In that case, getToggleAllRowsSelectedProps works fine.
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox {...row.getToggleRowSelectedProps()}
            Cell: ({ row }) => (
              <div  >
                <IndeterminateCheckbox click={click} select={select2} rows={row} /> 
              </div>
            ),
          },
          {
            accessor: 'Rank',
          Header: () => (
            <div style={{display: 'inline-flex'}}>
            <p>Rank</p>
            <button style={{border:"#fafafa",background:"#fafafa",outline:"none",margin:"0",padding: "0"}} onClick={ (e) => {handlesort(e,"Rank","asc")}}><UnfoldMoreIcon /></button>
            </div>
          ),

        },
          {
            id:'Country',
            Header: "Country",
            Cell: ({ row }) => {
              return(
                    <div className="tooltip">{row.original.Country}
                        <span className="tooltiptext">{row.original.cod.replace(/_/g," ")}</span>
                  </div>
            )},
          },
          {
            Header: () => (
              <div style={{display: 'inline-flex'}}>
              <p># per Million</p>
              <button style={{border:"#fafafa",background:"#fafafa",outline:"none",margin:"0",padding: "0"}} onClick={ (e) => {handlesort(e,"CaseM")}}><UnfoldMoreIcon /></button>
              
              </div>
            ),
            accessor: 'Tendenza',
          },
          {
            id:'CaseNew',
            Header: () => (
              <div style={{display: 'inline-flex'}}>
              <p># Daily</p>
                            <button style={{border:"#fafafa",background:"#fafafa",outline:"none",margin:"0",padding: "0"}} onClick={ (e) => {handlesort(e,"CaseNew")}}><UnfoldMoreIcon /></button>
              </div>
            ),
            Cell: ({ row }) => {
              return(
              <div  >
                    <div className="tooltip">{formatNumber(row.original.CaseNew)}
                        <span className="tooltiptext">Total: {formatNumber(row.original.Casitot)}</span>
                      </div>
              </div>
            )},
          },
        ])
      }
    )
    const handleChangePage = (event, newPage) => {
      gotoPage(newPage)
    }
  
    const handleChangeRowsPerPage = event => {
      setPageSize(Number(event.target.value))

    }
    // Render the UI for your table
    return (
      <TableContainer style={{overflow:"hidden",maxWidth:"100%"}}>
        <TableToolbar
          numSelected={Object.keys(selectedRowIds).length}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          handleswitch={switchs}
          date={date}
          handledate={handledata}
          handlesearch={handlesearch}
          handletots={HandleTot}
          totstates={totstate}
        />
        <MaUTable {...getTableProps()} >
          <TableHead  >
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...(column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                      style={{padding:"1px"}}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody >
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()} style={{padding:"1px"}} >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
  
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: 'All', value: data.length },
                ]}
                colSpan={20}
                labelRowsPerPage={"Rows:"}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    )
  }
  
  EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
    select2:PropTypes.array.isRequired,
    click:PropTypes.func.isRequired,
    switchs:PropTypes.func.isRequired,
    handledata:PropTypes.func.isRequired,
    handlesort:PropTypes.func.isRequired,
    handlesearch:PropTypes.func.isRequired,
    globalFilter:PropTypes.string.isRequired,
    premuto:PropTypes.bool.isRequired,
    HandleTot:PropTypes.func.isRequired,
    totstate:PropTypes.bool.isRequired,
  }

  export default EnhancedTable
  