import React from "react"
import "./Rss.css"
function Comp(props){
    return(
        <div className="rss">
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            {props.title} 
            </a>
        
    </div>
    )
}
export default Comp