import React from "react"
import "./footer.css"
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

function Footer(){
    const [show,setShow]= React.useState(false)
    const [showte,setShowte]= React.useState(false)
    const [news,setNews]= React.useState(false)
    let stylesh=null
    let stylete=null
    if(show){
        stylesh={display:"block"}
    }
    if(showte){
        stylete={display:"block"}
    }
    document.getElementById("news").style.display = "none";

    if(news){
        document.getElementById("news").style.display = "block";
    }

    return(
        <footer className="footer" >

            <Grid container spacing={1}>
            <Grid item xs={12}>
            <div className="pata" style={stylesh}>
            <h1>                                               WHAT  </h1>
                    <p>
                    Which are the countries where the COVID-19  outbreak is growing the most? <br></br>Here you can find the rank of the countries in which the situation is more critical measured by comparing the numbers of both cases and deaths to the population of each country.
                    <br></br>This computation allows for a more useful ranking system than just analyzing which countries have the highest numbers.
                    Select  the countries, check the graph and compare them!<br></br>covidranks.com  calculate ranks and trends starting from a 7 days  simple moving correlated to countries population 
                    </p>
            </div>
            <div className="pata" style={stylete}>
            <h1>                                               DISCLAIMER  </h1>
        <p>covidranks.com is an independent service and is not  related to any government and public or private organizations.</p>
        <p> covidranks.com  is provided "as is” without warranties of any kind either express or implied </p>
        <p> covidranks.com  cannot guarantee the correctness of information contained within, or of information obtained from third parties </p>
        <p> covidranks.com is  not responsible for any decisions made, based on information or links provided by covidranks.com. </p>
        <p> covidranks.com uses open data from https://data.europa.eu/ </p>
        


            </div>
        <Grid container justify="center" spacing={2}>
        <Button  variant="outlined" default="rgba(0, 0, 0, 0.87)"  onClick={() =>{setShow(!show);setShowte(false);setNews(false)}}>
                What
            </Button>

            <span style={{marginLeft:"2%"}}></span> 
            <Button variant="outlined" default="rgba(0, 0, 0, 0.87)"   onClick={() =>{setShowte(!showte);setShow(false);setNews(false)}} >
            TERMS
            </Button>
            <span style={{marginLeft:"2%"}}></span> 
            <Button  variant="outlined" default="rgba(0, 0, 0, 0.87)"  onClick={() =>{setNews(!news);setShow(false);setShowte(false)}}>
                subscribe
            </Button>
            <a rel="license" href="http://creativecommons.org/licenses/by-nd/4.0/"><img alt="Creative Commons License" style={{borderWidth:"0px",padding:"2%"}} src="https://i.creativecommons.org/l/by-nd/4.0/88x31.png" /></a>
            <a style={{padding:"2%",color:"rgba(0, 0, 0, 0.87)"}}  href="mailto:info@covidranks.com">info@covidranks.com</a> 

            </Grid>
            </Grid>
            </Grid>

        </footer>
    )
}
export default Footer

