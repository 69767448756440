import React from "react"
import CssBaseline from '@material-ui/core/CssBaseline'
import EnhancedTable from './EnhancedTable'
import Grafico from "./Grafico"
import Papa from "papaparse"
import flag from 'country-code-emoji';
import getcode from "./country"
import Up from "./up"
class Tabella extends React.Component{
  constructor(props){
    super(props)
    this.state={
      selected:[0,1,2,3,4],
      gt:[],
      data:[],
      dati:[],
      dategh:"",
      fill:[],
      datagrafico:[],
      skipPageReset:false,
      swiths:false,
      columns:[
        {
          Header: 'Rank',
          accessor: 'Rank',
          
        },

      ],
      value: "",
      premuto:false,
      date: new Date(),
      totstate:false,
    }
  }
  compareValues=(key, order = 'asc')=>{
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      let varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      let varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
      if(key==="Rank"){
        varA=varA.props.children
        varB=varB.props.children
      }
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }




  mapping=(dati,props)=>{
    if(props===null){
      return []
    }
    let len=props.length
    let mapped=dati.map(x => {
      let data={Data:x.DATE}
      for(let i=0;i<len;i++){
        data[props[i]]=x[props[i]]
      }
      return(data)
    })
    return mapped
  }

   mappi=(selects)=>{
     if(this.state.dati.length === 0){
       return null
     }
    let len=selects.length
    let datis=[]
    for(let i=0;i<len;i++){
      datis.push(this.state.dati[selects[i]].cod)
    }
    return datis
  }
  resetmap=(dati,selects)=>{
    let len=selects.length
    let datis=[]
    for(let i=0;i<len;i++){
      datis.push(dati[selects[i]].cod)
    }
    return datis
  }
   handleClick = (event,row) => {
    if(row !== undefined){
    }
    //event.isthis.state.Selected=true
    if(event !== undefined){
    if(event.target !== undefined ){
      if(event.target.checked === false){

        event.target.checked=false
        let selets=this.state.selected
        let k=selets.indexOf(Number(event.target.value))
        let sec=selets.splice(k,1)
        let c=this.mapping(this.state.datig,this.mappi(selets))
        this.setState(old =>({
          datagrafico : this.mappi(selets),
          Selected: sec,
          ut:c,
        }))
        return 0
      }else{
      event.target.checked = true
      let slc=this.state.selected
      slc.push(Number(event.target.value))
     let datag=this.mappi(slc)
     let ksda=this.mapping(this.state.datig,datag)
     this.setState(old => ({
       selected: slc,
       datagrafico:datag,
       ut:ksda,
     }))
    }
  }
  }
  };

  handlesearch=(e)=>{
    let key=e.target.value
    const dati=this.state.dati
    let fill=[]
    if (e.target.value !== "") {
    fill = dati.filter(item => {
      let lc = item.cod.toLowerCase().replace(/_/g," ");
      const filter = e.target.value.toLowerCase();

      if(lc.includes(filter)){
        return true
      }
      else{
         lc = item.Country.toLowerCase().replace(/[\u1000-\uFFFF]+/g,"").substring(1);

        return lc === filter
      }
    });
    } else {
    fill = this.state.dati;
    }
    let len=fill.length
    if(len>5){
      len=5
    }
    let c=this.resetmap(fill,[...Array(len).keys()])
    let ut=this.mapping(this.state.datig,c)
    this.setState({
      value: key,
      ut:ut,
      datagrafico:c,
      fill:fill,
      
    });
  }
//handleChange = (event) => {
////    setState({ ...state, [event.target.name]: event.target.checked });
//  }//
  handlesort=(e,key,and="desc")=>{
    let datis= this.state.dati.sort(this.compareValues(key,"desc"))
    if(this.state.premuto){
      datis= this.state.dati.sort(this.compareValues(key))
    }
    let len=datis.length
    if(len>5){
      len=5
    }
    let c=this.resetmap(datis,[...Array(len).keys()])
    let ut=this.mapping(this.state.datig,c)
    this.setState(old =>({
      dati :datis,
      ut:ut,
      datagrafico:c,
      keys:datis[0].Country,
      premuto:!old.premuto,
    }))
  }


  handledate=(date)=>{
    const year=date.getUTCFullYear()
    let month= date.getUTCMonth() +1
    let day=date.getUTCDate() 
    if(Date.now() <= date.getTime() ){
      this.setState(
        {
          date:date,
        }
      )
      return 0
    }
    let dategh = date.toLocaleDateString({ timeZone: 'UTC' })
    if(month < 10){
      month="0"+month
    }
    if(day < 10){
      day="0"+day
    }
    if(new Date().getUTCDate() === day && new Date().getUTCMonth() +1 === month){
      this.rank(this.state.swiths)
      this.graph(this.state.swiths)
      this.setState(
        {
          date:date,
          dategh:"",
        }
      )
      return 0
    }
    this.rank(this.state.swiths,year+"-"+month+"-"+day)
    this.graph(this.state.swiths)
    this.setState(
      {
        date:date,
        dategh:dategh,
      }
    )
  }

  rank=(shodeath,date)=>{
    let ur="/rank"
    if(shodeath){
      ur +="d"
    }
    if(date !== undefined){
      ur="/data/rank"
      if(shodeath){
        ur +="d"
      }
      ur+="-"+date
    }
    let select=[]
    //var dateObj = new Date();
    //var month = dateObj.getUTCMonth() + 1; //months from 1-12
    //var day = dateObj.getUTCDate();
    //var year = dateObj.getUTCFullYear();
    //let datas=year+"-"+month+"-"+day
    Papa.parse(ur+".csv", {
    download: true,
    header:true,
    dynamicTyping:true,
    step: function(row) {
      if(row.data.Rank === null){
        return 0
      }
      let cod=getcode(row.data.Country.replace(/_/g," ")) //
      row.data.cod=row.data.Country
      let count=cod
      if(cod !== null){
        count=flag(cod) + " " + cod
      }
      if(count === null){
        count=row.data.Country.replace(/_/g," ")
      }
      if(row.data.CaseNew ===null){
        row.data.CaseNew=0
      }
    row.data.Rank=<b>{row.data.Rank}</b>
    //= 
      row.data.Country=count
    row.data.Tendenza=<Up casi={row.data.CaseM.toFixed(1)} rank={row.data.Rank} trend={row.data.Tendenza}/> //<ArrowUpwardIcon style={{ color: red[500] }} />     
    select.push(row.data)
    },
    complete: ()=>{ 
        //let lab=Object.keys(select[0]).map((x,index) => ( <Line type="monotone" dataKey={x} key={x} dot={false} stroke={this.getRandomColor()} /> ))
        this.setState({
            loading:false,
            dati:select,
          })
        }
    });
  }

  graph=(showgraph,sel=this.state.selected)=>{
    let ur="/graph"
    if(showgraph){
      ur +="d"
    }
    let datig=[]
    Papa.parse(ur+".csv", {
      download: true,
      header:true,
      dynamicTyping:true,
      step: function(row) {
        if(row.data.Rank === null || row.errors.length > 0){
          return 0
        }
        row.data.DATE = new Date(row.data.DATE).toLocaleDateString({ timeZone: 'UTC' })
          datig.push(row.data)
      },
      error: undefined,
      complete: ()=>{ 
        let datg=this.mappi(sel)
        this.setState({
          datagrafico:datg,
          ut:this.mapping(datig,datg),
          datig:datig
        })
      } //dot={<CustomizedDot />} 
    })
  }
  componentDidMount(){
    this.rank(false)
    this.graph(false)
  }
  handleswitch=(e,st)=>{
    if(e === undefined){
      return(this.state.swiths)
    }
    if(e.target.checked){
      this.rank(true)
      if(this.state.totstate){
       this.graphtot(true) 
      }else{
      this.graph(true)
      }
      this.setState(old =>({
        swiths:!old.swiths,
      }))
    }else{
      this.rank(false)
      if(this.state.totstate){
        this.graphtot(false) 
       }else{
       this.graph(false)
       }
      this.setState(old =>({
        swiths:!old.swiths
      }))
    }
  }

  graphtot=(showgraph,sel=this.state.selected)=>{
    let ur="/graphtot"
    if(showgraph){
      ur +="d"
    }
    let datig=[]
    Papa.parse(ur+".csv", {
      download: true,
      header:true,
      dynamicTyping:true,
      step: function(row) {
        if(row.data.Rank === null || row.errors.length > 0){
          return 0
        }
        row.data.DATE = new Date(row.data.DATE).toLocaleDateString({ timeZone: 'UTC' })
          datig.push(row.data)
      },
      error: undefined,
      complete: ()=>{ 
        let datg=this.mappi(sel)
        this.setState({
          datagrafico:datg,
          ut:this.mapping(datig,datg),
          datig:datig
        })
      } //dot={<CustomizedDot />} 
    })
  }
  HandleTot=(e,st)=>{
    if(e === undefined){
      return(this.state.totstate)
    }
    if(e.target.checked){
      if(this.state.swiths){
        
        this.graphtot(true)
      }else{
        this.graphtot(false)

      }
      this.setState(old =>({
        totstate:!old.totstate,
      }))
    }else{
      if(this.state.swiths){
        
        this.graph(true)

      }else{
        this.graph(false)

      }
      this.setState(old =>({
        totstate:!old.totstate
      }))
    }
  }
  render(){
    return (
    <div > 
      <CssBaseline />
      <EnhancedTable
        columns={this.state.columns}
        data={ this.state.fill.length === 0 ? this.state.dati : this.state.fill }
        globalFilter={this.state.value}
        skipPageReset={true}
        select2={this.state.selected}
        click={this.handleClick}
        switchs={this.handleswitch}
        date={this.state.date}
        handledata={this.handledate}
        handlesort={this.handlesort}
        key={this.state.keys}
        handlesearch={this.handlesearch}
        premuto={this.state.premuto}
        HandleTot={this.HandleTot}
        totstate={this.state.totstate}
      />
    <Grafico dati={this.state.datagrafico} gs ={this.state.gt} ut={this.state.ut} date={this.state.dategh}  />
    </div>
  )
}
}
export default Tabella
