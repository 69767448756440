import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Footer from "./Footer"
import * as serviceWorker from './serviceWorker';
ReactDOM.render(
    <App />,
  document.getElementById('root')
);
ReactDOM.render(
    <Footer />,
  document.getElementById('Footer')
);
serviceWorker.unregister();

