import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

const useStyles = makeStyles((theme) => ({
    root:{
      display:"inherit",
      marginLeft:"2%",
      float: "right",
      maxWidth:"30%",
    },
    "@media (max-width: 900px)": {
      root:{
        marginLeft:"5px",
        maxWidth:"20%",

      }
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 60,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
  }));

export default function Casi(props) {
    const classes = useStyles()

    return (
      <div className={classes.root}> 

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Fragment>
    <DatePicker
        disableToolbar
        variant="inline"
        value={props.date}
        onChange={props.handledate}
      />
</Fragment>
</MuiPickersUtilsProvider>
    </div>
  );
}