import React from 'react';
import './App.css';
import Tabella from "./Tabella"
import Rss from "./Rss"
import Header from "./Header"
function App () {
  return(
    <>
    <Header/>
    <Tabella />
    <Rss />
    </> 
  )
  }

export default App;
 //<Grafico dati={this.state.dati}/>

