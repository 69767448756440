import Comp from "./Rsscomp"
import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      marginLeft:"10%",
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));
  
 function Rss(){
    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(true)
    const classes = useStyles();
    useEffect(() => {
        const fetchData = async () => {
            let Parser = require('rss-parser');
            let parser = new Parser();
            let feed = await parser.parseURL("/rss/");
            feed.items=feed.items.splice(0,5) 
          setData(feed.items); //            <iframe title="amazon" src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=health&banner=0G5GGJ1N5H018KBVX6R sm={3}2&f=ifr&linkID={{link_id}}&t=covis0a-20&tracking_id=covis0a-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style=H{border:"none"}} frameBorder="0"></iframe>

          setLoading(false)
        };
        fetchData();
      }, []);
        return(
            <div style={{marginBottom:"5%"}}>
            <h1 style={{marginLeft:"2%"}}> <b> last  COVID-19 Global News </b></h1>
            {loading ? <div className={classes.root}>
                        <CircularProgress /> 
                        </div>
                    : 
                    data.map(x => <Comp title={x.title} link={x.link} key={x.title} />)}
            <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <iframe style={{width:"120px",height:"240px"}} title="book" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-eu.amazon-adsystem.com/e/cm?ref=tf_til&t=covis-21&m=amazon&o=29&p=8&l=as1&IS1=1&asins=B088HGDY1S&linkId=5e4bcedac86a sm={3}b0c1d2564 sm={3}78fcc6a sm={3}590e&bc1=ffffff<1=_blank&fc1=6& sm={3}lc1=006c sm={3}0&bg1=ffffff&f=ifr"></iframe>
              </Grid>
              <Grid item xs={6} sm={3}>            
                <iframe style={{width:"120px",height:"240px"}} title="book2" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-eu.amazon-adsystem.com/e/cm?ref=tf_til&t=covis-21&m=amazon&o=29&p=8&l=as1&IS1=1&asins=B088F4ZLPP&linkId=25160 sm={3}6c sm={3}94eccbfc28b12064 sm={3}0f88462 sm={3}bf&bc1=ffffff<1=_blank&fc1=6& sm={3}lc1=006c sm={3}0&bg1=ffffff&f=ifr"></iframe>
              </Grid>
              <Grid item xs={6} sm={3}>            
                <iframe style={{width:"120px",height:"240px"}} title="mask" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-eu.amazon-adsystem.com/e/cm?ref=tf_til&t=covis-21&m=amazon&o=29&p=8&l=as1&IS1=1&asins=B087JWC11D&linkId=219fdfe982f61 sm={3}f082988a42e9129246& sm={3}bc1=ffffff<1=_blank&fc1=6& sm={3}lc1=006c sm={3}0&bg1=ffffff&f=ifr"></iframe>
              </Grid>
              <Grid item xs={6} sm={3}>
              <iframe style={{width:"120px",height:"240px"}} title="mask2" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-eu.amazon-adsystem.com/e/cm?ref=tf_til&t=covis-21&m=amazon&o=29&p=8&l=as1&IS1=1&asins=B085RB54SP&linkId=10ec69 sm={3}67 sm={3}abff48db8276f sm={3}72165 sm={3}ab916c sm={3}&bc1=ffffff<1=_blank&fc1=6& sm={3}lc1=006c sm={3}0&bg1=ffffff&f=ifr"></iframe>
              </Grid>
              </Grid>
            </div>
            </div>
            )
    }

export default Rss