import React from "react"
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import red from "@material-ui/core/colors/red"
import green from "@material-ui/core/colors/green"
function Up(props){
    let dist=6-props.casi.toString().length
    dist=dist*8.15 
    let arrow=<ArrowUpwardIcon style={{ color: red[500] }} />
    switch(props.trend){
        case 2:
            arrow= <ArrowUpwardIcon style={{ color: red[500] , marginLeft: dist.toString()+"px" }} />
            break
        case 0:
            arrow=<ArrowDownwardIcon style={{ color: green[500], marginLeft: dist.toString()+"px" }} />
            break
        case 1:
            arrow= <img 
            style={{ marginLeft: (dist+4).toString()+"px",paddingTop:"2px" }}
            src="/ugualeg.svg" 
            alt="Equals"
            height="16"
            width="16" />  //<SvgIcon component={Uguale} viewBox="0 0 600 476.6" htmlColor="#f29010" />
            break
        default:
            break;
    }
    return(
        <div style={{display:"inline-flex"}}>
          {props.casi}  {arrow}
        </div>
    )
}
export default Up