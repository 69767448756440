import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer
} from 'recharts';
import getcode from "./country"
let newcolor;
const getRandomColor=()=> {
  newcolor="#"+(Math.random()*0xFFFFFF<<0).toString(16);
if(newcolor.length<7){
  getRandomColor();
}
return newcolor
}
let pos=[]
let max=0
const CustomizedDot = (props) => {
  let date1 = new Date().toLocaleDateString(undefined,{ timeZone: 'UTC' })
  if(props.dates !== ""){
    date1=props.dates
  }
    const {cx, cy, payload, dataKey} = props;
    if (payload.Data === date1 || props.index === max-1 ) {
      //const k"/svg/"+ payload.code.toString() +".svg"
      //console.log(kIt)
      let y=cy -2
      let len=pos.length
      for(let i=0; i<len;i++){
        if(pos[i]+21 >=  y && pos[i] <= y ){
            y+= (y-pos[i])         
        }
      }
      pos.push(y)
      return (
        <svg >
        <g>
          <text x={cx} y={y} width={20} height={20}  fill={props.stroke}>{getcode(dataKey.replace(/_/g," "))}</text>
        </g>
      </svg>
      )
    }
     return null
    };
const mindata=(dati,date)=>{
  let len = dati.length
  let i=0
  let sky=0
  if(date !== ""){
    if(dati[0] !== undefined){
    for(let i=len-1 ;i > 0; i--){
      if(dati[i].Data === date){
        sky=i
        break
      }
    }}
    dati = dati.slice(0,sky+1)
  }
  let sp=0
loop1:
  for(i=0; i < len ; i++){
    if(dati[i] === undefined){
      return []
    }
    for (let key of Object.keys(dati[i])) {
      if(dati[i][key] > 1){
        sp=i
      break loop1
    }
  }
  }
  return dati.slice(sp,dati.length)
}
//const country=["italy"]
const colors=["red","gray","green"," blue","orange"]

const CustomizedAxisTick = (props) => {
    const {x, y,  payload} = props;
		
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" padding fill="#666" transform="rotate(-69)">{payload.value}</text>
      </g>
    );
};

function Grafico(props){
  pos=[]
  let dati;
  if(props.ut !== undefined){
   dati=mindata(props.ut,props.date)
   max = dati.length 
  } 
  if(props.dati === null ){
    return null
  }
  let lines=props.dati.map((x,index) =>{
    if(index > 5 ){
      return(
        <Line type="monotone" dataKey={x} stroke={getRandomColor()} strokeWidth={3.5} dot={<CustomizedDot dates={props.date}/>} key={x} /> 
        )
    }

    return(
    <Line type="monotone" dataKey={x} stroke={colors[index]} strokeWidth={3.5} dot={<CustomizedDot dates={props.date} />} key={x} /> )
    })
    
    return (
      <div >
        <ResponsiveContainer width="100%" height={400}  >
          <LineChart

            data={dati}
            margin={{
              top: 5, right: 30, left: 0, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis height={100} dataKey="Data" tick={<CustomizedAxisTick/>} />
            <YAxis width={40} />
            <Tooltip /> 
            {lines}
          </LineChart>
          </ResponsiveContainer>
      </div>
    );
  }

  export default Grafico