import React from "react"
import Logo from "./logo.svg"
import {  makeStyles } from '@material-ui/core/styles';
import "./header.css"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
      padding:"4px",
      display:"grid",
      overflow:"hidden"
    },
},
    '@media (min-width: 600px)':{
        root:{
          position: "absolute",
          right:"5px",
        }
  },
}));

function Header(){
    const classes = useStyles();
    return(
        <header className="Header">
        <div className="logo" >
           <img src={Logo} alt="Logo" ></img>
        </div>
        <div className={classes.root}>


        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" style={{paddingLeft:"4%",marginTop:"7%"}}>
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="47LSMHHF87ASJ" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_IT/i/scr/pixel.gif" width="1" height="1" />
        </form>
        </div>
        </header>
    )
}

export default Header