import React from 'react'
import Switchs from "./switch"
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Casi from "./Casi"
import "./switch.css"
import Search from "./Search"
const useToolbarStyles = makeStyles(theme => ({
  root: {
    padding:"0px",
    margin:"0px",
    float:"right",
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

const TableToolbar = props => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    handleswitch,
    handledate,
    date,
    globalFilter,
    handlesearch,
    handletots,
    totstates,
  } = props
  let showmilion=true;
  if(window.screen.width  <= 450){
    showmilion=false;
  }
  return (
    <div>
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
     <Switchs handleswitch={handleswitch} name={{primo:"Cases",secondo:"Deaths"}} />
     { showmilion ? <div style={{float:"left"}}> { <Switchs handleswitch={handletots} totstate={totstates} name={{primo:"#/Million",secondo:"Total"}} />  } </div> : null}


    <Casi date={date} handledate={handledate} />   
    <Search handlesearch={handlesearch} globalFilter={globalFilter} />

    </Toolbar>
    { showmilion ? null : <div style={{float:"left"}}> {<Switchs handleswitch={handletots} totstate={totstates} name={{primo:"#/Million",secondo:"Total"}} /> } </div>}

    </div>
  )
}

TableToolbar.propTypes = {
  handledate:PropTypes.func.isRequired,
  date:PropTypes.instanceOf(Date),
  handlesearch:PropTypes.func.isRequired,
  globalFilter:PropTypes.string.isRequired,
  HandleTots:PropTypes.func.isRequired,
  totstates:PropTypes.bool.isRequired,
}

export default TableToolbar
